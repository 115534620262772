<template>
  <div class="pc-template-component">
    <div class="templateContentSider">
      <div v-for="(item,index) in contentSave" :key="'template'+ index">
        <component
            v-if="registerTemplateName_SAVE.includes(`C${item.type.replace(/-/g, '')}`)"
            :is="`C${item.type.replace(/-/g, '')}`"
            :jsonContent="item.dataReference || []"
            :fatherContent="item"
            :father="item"
            :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
            @fatherCall="setUpdateJSON"
            @updateRefDcu="updateRefDcuData"
            @cacheDatamodel="cacheDatamodelData"
            :disabled="disabled"
            :host="hostKey"
            :listHost="dataKey"
            :editStatus="editStatus"
            ref="childComponents"
        ></component>
        <C00000000000000000000000000000000
          :jsonContent="item.dataReference || []"
          ref="childComponents"
          :jsonReferenceListContent="jsonReferenceListContent"
          :jsonLanguageContent="jsonLanguageContent"
          :fatherContent="item"
          :father="item"
          :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
          @fatherCall="setUpdateJSON"
          @updateRefDcu="updateRefDcuData"
          @cacheDatamodel="cacheDatamodelData"
          :disabled="disabled"
          :host="hostKey"
          :listHost="dataKey"
          :editStatus="editStatus"
          v-else
        ></C00000000000000000000000000000000>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
export default {
  uuid: '00000000-0000-0000-0000-000000000000',
  data () {
    return {
      formLabelAlign: {},
      callBackJson: {},
      checked: false,
      translate: {},
      contentSave: {}
    }
  },
  created () {
    if (this.jsonReferenceListContent && this.jsonReferenceListContent.length>0) {
      this.jsonReferenceListContent.forEach(element => {
        if (this.fatherContent.type === element.uuid) {
          this.contentSave = JSON.parse(element.value).attributes
        }
      })
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    }
  },
  methods: {
    updateRefDcuData(data){
      this.$emit("updateRefDcu", data)
    },
    cacheDatamodelData(){
      this.$emit("cacheDatamodel")
    },
    setUpdateJSON (data) {
      this.callBackJson[data.key] = data.value;
      if (data.value == undefined) {
        delete this.callBackJson[data.key];
      }

      let updataJson = JSON.parse(JSON.stringify(this.callBackJson))
      let emmitValue = Object.keys(updataJson).length > 0 ? updataJson : undefined
      this.formLabelAlign.value = emmitValue

      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: emmitValue
      });
    },
    getMessage (status) {
      if (this.translate.language === undefined) {
        return false
      }
      try {
        if (
          this.translate.language[this.setLang].custom[this.content.key] !==
          undefined
        ) {
          // eslint-disable-next-line standard/computed-property-even-spacing
          return this.translate.language[this.setLang].custom[this.content.key][
            status
          ]
        } else if (
          this.translate.language[this.setLang].base[this.content.key] !==
          undefined
        ) {
          // eslint-disable-next-line standard/computed-property-even-spacing
          return this.translate.language[this.setLang].base[this.content.key][
            status
          ]
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    }
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    setLang () {
        return "en-US"
    //   return this.$store.getters.get(S_LANG)
    },
    hostKey(){
      return this.host + "." + this.fatherContent.key
    },
  },
  mounted() {

    this.formLabelAlign.value  = this.datamodel
  },
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost", "jsonReferenceListContent", "jsonLanguageContent", "contextData", "editStatus", "father"]
}
</script>
<style lang="scss" scoped>
.pc-template-component {
  font-size: 14px;
}
.templateContentSider{
  padding: 10px 0;
//   border-bottom: 1px solid #C0C4CC;
}
</style>
