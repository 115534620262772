<template>
  <div class="item-content" v-show="visible">
    <el-form
      ref="childForm"
      @submit.native.prevent
      :rules="rules"
      :model="formLabelAlign"
      label-position="top"
    >
      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-date-picker
          :disabled="cannotEdit"
          v-model="formLabelAlign.value"
          type="datetime"
          value-format="timestamp"
          placeholder="choose date time"
        ></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang'
import { mapGetters } from 'vuex'
import { toUTC } from "@/utils/time";

export default {
  uuid: '00000000-0000-0000-0000-000000000005',
  components: {},
  props: [
    'jsonContent',
    'fatherContent',
    'datamodel',
    'disabled',
    'host',
    "listHost",
    'contextData',
    'editStatus'
  ],
  data() {
    return {
      formLabelAlign: {
        value: 0
      },
      interval: undefined,
      upstatus: undefined
    }
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible
      }
      return this.fatherContent.displayReference.visible
    },
    hostKey() {
      return this.host + '.' + this.fatherContent.key
    },
    // 收货管理页面中的发货时间
    isDeliverTime() {
      const query = this.$route.query;
      return query.dcp_type === 'receive' && this.fatherContent.key === 'textdatetime_000';
    },
    isDateType() {
      return this.fatherContent.controlReference.dataSource.includes('Preset.DeviceTime') || this.fatherContent.controlReference.dataSource.includes('Local.DeviceTime');
    },
    // 收货管理页面中的收货时间 - 待收货状态 - 此时应该设置成空
    isWaitReceiveTime() {
      const query = this.$route.query;
      const receiveInfo = this.$store?.state?.receiveInfo;
      return query.dcp_type === 'receive' && this.fatherContent.key === 'tracktime' && receiveInfo.receive_state === 'WAIT_RECEIVE';
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit('fatherCall', {
          key: this.fatherContent.key,
          value: val.value
        })
      },
      deep: true
    },
    datamodel: { // 监听 datamodel 变化，因为组件是全部更新，如果数据后更新，将无法看到最新数据
      immediate: true,
      handler: function (val) {
        if (val) {
          const query = this.$route.query;
          if (query.dcp_type === 'receive' && this.fatherContent.key === 'textdatetime_000') { // 收货管理页面中的发货时间需要取收货列表中的发货时间
            this.renderDeliverTime();
          } else if (this.isDateType && this.isWaitReceiveTime) {
            this.formLabelAlign.value = undefined; // 收货管理页面中的收货时间 - 待收货状态 - 此时应该设置成空
          } else {
            typeof val === 'object' ? (this.formLabelAlign.value = new Date(val.value).getTime()) : (this.formLabelAlign.value = val)
          }
        }
      }
    },
    "$store.state.receiveInfo": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.renderDeliverTime();
        }
      }
    }
  },
  methods: {
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    },
    renderDeliverTime() {
      const query = this.$route.query;
      if (query.dcp_type === 'receive' && this.fatherContent.key === 'textdatetime_000') { // 收货管理页面中的发货时间需要取收货列表中的发货时间
        const receiveInfo = this.$store?.state?.receiveInfo;
        const utcTime = toUTC(receiveInfo.deliver_time);
        const timestamp = new Date(utcTime).getTime();
        this.formLabelAlign.value = timestamp;
      }
    }
  },
  created() {},
  mounted() {
    if (this.datamodel !== undefined) {
      typeof this.datamodel === 'object'
        ? (this.formLabelAlign.value = new Date(this.datamodel.value).getTime())
        : (this.formLabelAlign.value = new Date(this.datamodel).getTime())
      if (typeof this.datamodel === 'string') {
        this.formLabelAlign.value = new Date(parseInt(this.datamodel)).getTime()
      }
    } else {
      if (this.isDateType && !this.isDeliverTime) {
        this.formLabelAlign.value = Date.now()
      } else if (this.isDateType && this.isDeliverTime) {
        this.renderDeliverTime();
      } else {
        this.formLabelAlign.value =
          this.fatherContent.displayReference.defaultValue || undefined
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item-content {
  padding-top: 10px;
  margin-bottom: 5px;
}
</style>
