<template>
  <div class="item-content" v-show="visible">
    <el-form
      ref="childForm"
      @submit.native.prevent
      :rules="rules"
      :model="formLabelAlign"
      label-position="top"
    >
      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-date-picker
          :disabled="cannotEdit"
          v-model="formLabelAlign.value"
          type="date"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang'
import { mapGetters } from 'vuex'
export default {
  uuid: '00000000-0000-0000-0000-000000000006',
  components: {},
  props: [
    'jsonContent',
    'fatherContent',
    'datamodel',
    'disabled',
    'host',
    "listHost",
    'contextData',
    'editStatus'
  ],
  data() {
    return {
      formLabelAlign: {
        value: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible
      }
      return this.fatherContent.displayReference.visible
    },
    hostKey() {
      return this.host + '.' + this.fatherContent.key
    },
    content() {
      if(this.fatherContent && this.fatherContent.itemType){
        return this.fatherContent.itemType
      }
      return Object.keys(this.jsonContent).length
        ? this.jsonContent
        : this.fatherContent;
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit('fatherCall', {
          key: this.fatherContent.key,
          value: val.value
        })
      },
      deep: true
    }
  },
  methods: {
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    }
  },
  created() {},
  mounted() {

    if (this.datamodel !== undefined) {
      typeof this.datamodel === 'object'
        ? (this.formLabelAlign.value = new Date(this.datamodel.value).getTime())
        : (this.formLabelAlign.value = new Date(this.datamodel).getTime())
      if (typeof this.datamodel === 'string') {
        this.formLabelAlign.value = new Date(parseInt(this.datamodel)).getTime()
      }

    } else {

      if (
        this.fatherContent.controlReference.dataSource.includes(
          'Preset.DeviceTime'
        ) || this.fatherContent.controlReference.dataSource.includes(
              'Local.DeviceTime'
              )
      ) {
        this.formLabelAlign.value = Date.now()
      } else {
        this.formLabelAlign.value =
          this.fatherContent.displayReference.defaultValue || undefined
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item-content {
  padding-top: 10px;
  margin-bottom: 5px;
}
</style>
