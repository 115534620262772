<template>
  <div class="bsr-texttimestamp-component" v-show="false"></div>
</template>
<script>
export default {
  uuid: "00000000-0000-0000-0000-000000000010",
  data() {
    return {
      formLabelAlign: {
        value: Date.now()
      }
    }
  },
  created() {
    this.$emit("fatherCall", {
      key: this.fatherContent.key,
      value: Date.now()
    });
  },
  props: ["jsonContent", "fatherContent", "datamodel", "host","listHost", "contextData", "editStatus"]
};
</script>