<template>
  <div class="item-content" v-show="visible">
    <el-form
      ref="childForm"
      @submit.native.prevent
      :rules="rules"
      :model="formLabelAlign"
      label-position="top"
    >
      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <div style="position: relative">
          <div class='el-input-number'>
            <span class="el-input-number__decrease" @click.stop="handleDecrease">-</span>
            <span class="el-input-number__increase" @click.stop="handleIncrease">+</span>
            <el-input
                  v-model="formLabelAlign.value"
                  @blur="alignedValue"
                  clearable
                  :max="fatherContent.controlReference.max"
                  :min="fatherContent.controlReference.min"
                  :disabled="cannotEdit"
                  type="number"
              ></el-input>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang';
import { mapGetters } from 'vuex'
export default {
  uuid: "00000000-0000-0000-0000-000000000004",
  components: {},
  props: [
    "jsonContent",
    "fatherContent",
    "datamodel",
    "disabled",
    "host",
    "listHost",
    "contextData",
    "editStatus"
  ],
  data() {
    return {
      NumberData:undefined,
      formLabelAlign: {
        value: undefined
      }
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: "blur"
          }
        ]
      };
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key;
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {

        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    }
  },
  methods: {
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    },
    handleDecrease() {
        if(this.disabled){
          return
        }
        let finalValue = this.formLabelAlign.value - 0.01
        if(this.fatherContent.controlReference && finalValue < this.fatherContent.controlReference.min){
            this.fixedValue(this.fatherContent.controlReference.min)
            return
        }
        this.fixedValue(finalValue)
    },
    handleIncrease() {
        if(this.disabled){
          return
        }

        let finalValue = this.formLabelAlign.value + 0.01
        if(typeof this.formLabelAlign.value === 'string'){
          finalValue = parseFloat(this.formLabelAlign.value) + 0.01
        }

        if(this.fatherContent.controlReference && finalValue > this.fatherContent.controlReference.max){
            this.fixedValue(this.fatherContent.controlReference.max)
            return
        }
        this.fixedValue(finalValue)
    },
    fixedValue(e){

      if(typeof e === 'string'){
        this.formLabelAlign.value = parseFloat(e).toFixed(2)
      }else{
        this.formLabelAlign.value = e.toFixed(2)
      }
    },

    alignedValue(e) {
      let inputValue = e.target.value
      if(typeof inputValue === 'string'){
        inputValue = parseFloat(inputValue).toFixed(2)
      }else{
        inputValue = inputValue.toFixed(2)
      }
      if(this.fatherContent.controlReference && inputValue > this.fatherContent.controlReference.max){
          this.fixedValue(this.fatherContent.controlReference.max)
          return
      }

      if(this.fatherContent.controlReference && inputValue < this.fatherContent.controlReference.min){
          this.fixedValue(this.fatherContent.controlReference.min)
          return
      }

      this.fixedValue(e.target.value)
    }
  },
  created() {},
  mounted() {
    this.content =
      Object.keys(this.jsonContent) > 0 ? this.jsonContent : this.fatherContent

    if (this.datamodel !== undefined) {
      typeof this.jsonContext === 'object'
        ? (this.fixedValue(this.datamodel.value))
        : (this.fixedValue(this.datamodel))
    } else {
      this.fixedValue(this.fatherContent.displayReference.defaultValue)
      if(this.fatherContent.displayReference.defaultValue === ""){
          this.formLabelAlign.value = undefined
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.item-content {
  padding-top: 10px;
  margin-bottom: 5px;
}

.cover-input-number {
  position: absolute;
  top: 0;
  height: 40px;
  width: 100px;
  left: 40px;
  border: 0;
  padding: 0;
  z-index: 10;
  opacity: 0;
  text-align: center;
}
</style>
