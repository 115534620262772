<template>
  <div>
    <!-- 发货管理页面中的收获方 h5 和 web 都是一个下拉框组件 (共用) -->
    <shipment-receive-select
      v-if="unitKey === 'receivename'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />

    <text-input-for-name
      v-else
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import callApp from "@/services/sdk";
import {shipGroupNameDcuKeys, shipOrgNameDcuKeys} from "@/utils/config";
import { commonStoreModule } from "@/store/module/common";

import TextInputForName from '@/components/normal/TextInputForName.vue'
import ShipmentReceiveSelect from '@/components/shipment/receiveSelect.vue';

export default {
  inject: {
    commonSave: {
      default: {}
    }
  },
  uuid: '00000000-0000-0000-0000-000000000001',
  components: {
    TextInputForName,
    ShipmentReceiveSelect
  },
  props: [
    'jsonContent',
    'fatherContent',
    'datamodel',
    'disabled',
    'host',
    "listHost",
    'contextData',
    'editStatus'
  ],
  data() {
    return {
      showInputArea: false,
      itemdata: '',
      // callBackJson: {}, 不能有这个字段，否则会导致 OtherList 里面的 getNewValue 方法判断会走 element.callBackJson  319行
      formLabelAlign: {
        value: ''
      },
      tempData: '',
      label: '',
      dataRefValue: '',
      showDataRef: false,
      deleteRefData: '',

      scanColl: {},
      dataSourceChooseVisable: false,
      scannerResultVisible: false,
      currentScannerDataSource: '',

      scanSources: [],
      selectScanSourceType: "",
      selectScanSourceValue: "qrcode_single",
      scanSelect: "",

      scanResultText: [],
      resultType: "",
      isIos: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    }
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList',
      getChildPageContext: 'getChildPageContext'
    }),
    hostKey() {
      return this.host + '.' + this.fatherContent.key
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    unitKey() {
      return this.fatherContent.key;
    },
    refDcuContent() {
      var refContent =
        (this.getChildPageContext[this.hostKey] &&
          this.getChildPageContext[this.hostKey].refContent &&
          this.getChildPageContext[this.hostKey].refContent.value) ||
        this.datamodel
      return refContent
    },
  },
  methods: {
    hanldGetChildInfo(data) {
      // TODO 暂时方案 if 分支为了 Productlabels 能够提交数据
      // if (this.fatherContent.key === 'label') {
      //   this.callBackJson[this.fatherContent.key] = data;
      //   if (data == undefined) {
      //     delete this.callBackJson[this.fatherContent.key];
      //   }

      //   let updataJson = JSON.parse(JSON.stringify(this.callBackJson))
      //   let emmitValue = Object.keys(updataJson).length > 0 ? updataJson : undefined
      //   this.formLabelAlign.value = emmitValue

      //   this.$emit("fatherCall", {
      //     key: this.fatherContent.key,
      //     value: emmitValue
      //   });
      // } else {
      //   this.$emit("fatherCall", {
      //     key: this.fatherContent.key,
      //     value: data
      //   });
      // }
      this.formLabelAlign.value = data
    },
    setShipValue() {
      let roleInfo = commonStoreModule.getRoleInfo;
      let groupName = roleInfo.groupName;
      let suborgName = roleInfo.suborgName;
      if (shipGroupNameDcuKeys.includes(this.hostKey)) {

        this.formLabelAlign.value = groupName

      } else if(shipOrgNameDcuKeys.includes(this.hostKey)) {

        this.formLabelAlign.value = suborgName
      }
    },
  },
  created() {},
  mounted() {
    if (this.datamodel !== undefined) {
      if (Object.keys(this.datamodel).length === 0) {
        this.formLabelAlign.value = ''
      } else {
        let isCopy = this.$route.query.copyRecord;
        if (isCopy && this.fatherContent.key === 'skucode') {
          this.formLabelAlign.value = undefined
        }else{
          this.formLabelAlign.value = this.datamodel || '';
        }
      }

    } else {
      this.formLabelAlign.value =
        this.jsonContent.defaultValue ||
        this.fatherContent.displayReference.defaultValue ||
        undefined
    }
    this.itemdata = this.formLabelAlign.value
    this.label =
      this.fatherContent &&
      this.fatherContent.displayReference &&
      this.fatherContent.displayReference.label

    if (this.datamodel !== undefined) {
      let dataRefList = (this.contextData && this.contextData['dcpreferences'])
        ? this.contextData['dcpreferences'].filter(item => {
            return this.hostKey === item.storagekey
          })
        : []
      if (dataRefList && dataRefList.length !== 0) {
        this.dataRefValue = this.datamodel || ''
        this.formLabelAlign.value = this.datamodel || ''
        // this.isInputDisable = true
      } else {
        this.formLabelAlign.value = this.datamodel || ''
      }
    }

    this.dataRefValue = this.refDcuContent

    let refData =
      this.getChildPageContext[this.hostKey] &&
      this.getChildPageContext[this.hostKey].refContent &&
      this.getChildPageContext[this.hostKey].refContent.refData
    if (refData) {
      this.itemdata = this.dataRefValue
      this.$emit('updateRefDcu', { refData, action: 'update' })
    }

    if (
       this.fatherContent.controlReference && this.fatherContent.controlReference.dataSource.includes('Local.Scanner')
      ) {

          this.$store.state.scannerTextComponents.push(this); // 缓存vid组件实例
          window["web.closeScanDialog"] = () => {
            this.$store.state.scannerTextComponents.forEach(instance => {
              // 切换扫描类别时, 关闭扫描的弹窗
              instance.scannerResultVisible = false;
            });
          };
          callApp(
            "getSelectedTextSource",
            {
              type: "storage.getSelectedTextSource",
              data: {
                key: this.hostKey
              }
            },
            "cbGetSelectedVidSource"
          ).then(data => {
            this.selectScanSourceType = data.split(".")[0];
            this.selectScanSourceValue = data.split(".")[1];
            this.scanSelect = data;
            this.currentScannerDataSource = data
          });
      }
      setTimeout(()=> {
        this.setShipValue()
      }, 100)
  },
  watch: {
    itemdata: {
      immediate: true,
      handler(val) {
        this.formLabelAlign.value = val
        var va = val === '' || val === undefined ? undefined : val
        this.$emit('fatherCall', {
          key: this.fatherContent.key,
          value: va
        })
      }
    },
    formLabelAlign: {
      handler(val) {
        this.$emit('fatherCall', {
          key: this.fatherContent.key,
          value: val.value
        })
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">

</style>
