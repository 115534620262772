<template>
  <div class="item-content-multiline" v-show="visible">
    <el-form ref="childForm" @submit.native.prevent :rules="rules" :model="formLabelAlign">
      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-input
          :disabled="cannotEdit"
          v-model="itemdata"
          :placeholder="localized(`prompt`) || ''"
          type="textarea"
          :autosize="{ minRows: 2}"
          @focus="showInputView"
          class="rt-input"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="showInputArea" top="15vh" class="input-dialog" width="90%">
      <el-input
        type="textarea"
        v-model="tempData"
        :placeholder="localized(`prompt`) || ''"
        :autosize="{ minRows: 4}"
        ref="inputRef"
        autofocus
        show-word-limit
        :maxlength="fatherContent.controlReference.max"
        style="margin-top: -20px;"
      ></el-input>
      <el-row type="flex" class="row-bg" justify="space-between" style="margin-top: 20px;">
        <el-col :span="11">
          <el-button
            @click="rollbackData"
            size="small"
            round
            class="btn-white-color"
          >{{$t('app.btn_clear')}}</el-button>
        </el-col>
        <el-col :span="11">
          <el-button
            @click="confirmData"
            size="small"
            round
            class="btn-white-color"
          >{{$t('app.btn_confirm')}}</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import language from '@/utils/lang';
import { mapGetters } from 'vuex'
export default {
  uuid: "00000000-0000-0000-0000-000000000002",
  components: {},
  props: [
    "jsonContent",
    "fatherContent",
    "datamodel",
    "disabled",
    "host",
    "listHost",
    "contextData",
    "editStatus"],
  data() {
    return {
      showInputArea: false,
      itemdata: "",
      formLabelAlign: {
        value: ""
      },
      tempData: ""
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: "blur"
          },
          {
            min: this.fatherContent.controlReference.min || 1,
            max: this.fatherContent.controlReference.max,
            message: `From ${this.fatherContent.controlReference.min} to ${this.fatherContent.controlReference.max} character`,
            trigger: "blur"
          }
        ]
      };
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey(){
      return this.host + "." + this.fatherContent.key
    }
  },
  watch: {},
  methods: {
    showInputView() {
      if(this.disabled){
        return
      }
      this.showInputArea = true;
      setTimeout(()=>{
        this.$refs["inputRef"].focus()
        this.tempData = this.itemdata
      }, 100)
    },
    confirmData() {
      this.showInputArea = false;
      this.itemdata = this.tempData;
      this.formLabelAlign.value = this.itemdata;
      this.$refs.childForm.validate();
    },
    rollbackData() {
      this.showInputArea = false;
      this.tempData = this.itemdata;
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  created() {},
  mounted() {
    if (this.datamodel !== undefined) {

      if (Object.keys(this.datamodel).length === 0) {
        this.itemdata = ''
      } else {
        this.itemdata = this.datamodel || '';
      }

    } else {
      this.itemdata =
              this.jsonContent.defaultValue ||
              this.fatherContent.displayReference.defaultValue ||
              undefined
    }
    this.formLabelAlign.value = this.itemdata;
  },
  watch: {
    itemdata: {
      immediate: true,
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val
        });
      }
    },
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    }
  }
};
</script>
<style lang='scss'>
.item-content-multiline {
  text-align: left;
  margin-top: 30px;
}

.rt-input.el-textarea {
  .el-textarea__inner {
    height: 80px !important;
    border: none !important;
    border-radius: 2px;
    background-color: #F9F9F9 !important;
  }
}

.rt-input.el-textarea.is-disabled {
  .el-textarea__inner {
    background-color: #f5f7fa !important;
    border-color: transparent !important;
    color: #333333 !important;
    cursor: not-allowed !important;
  }
}

.rt-input textarea:disabled {
  opacity: 1 !important;
}
</style>
