<template>
  <div class="item-content" v-show="visible">
    <el-form
      ref="childForm"
      @submit.native.prevent
      :rules="rules"
      :model="formLabelAlign"
      label-position="top"
    >
      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <div class="boolean_switch_container">
          <el-radio-group v-model="formLabelAlign.value" class="boolean_switch" style="width: 100%" size="small" :disabled="cannotEdit">
            <el-radio :label="true">{{$t('app.switch_true')}}</el-radio>
            <el-radio :label="false">{{$t('app.switch_false')}}</el-radio>
          </el-radio-group>
        </div>

      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang'
import { mapGetters } from 'vuex'
export default {
  uuid: '00000000-0000-0000-0000-000000000007',
  components: {},
  props: [
    'jsonContent',
    'fatherContent',
    'datamodel',
    'disabled',
    'host',
    "listHost",
    'contextData',
    'editStatus'
  ],
  data() {
    return {
      content: {},
      formLabelAlign: {
        value: undefined
      },
      initStatus: true
    }
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    rules() {
      return {
        value: [
          {
            type: 'boolean',
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible
      }
      return this.fatherContent.displayReference.visible
    },
    hostKey() {
      return this.host + '.' + this.fatherContent.key
    }
  },
  watch: {
    formLabelAlign: {
      immediate: true,
      handler(val) {

        console.log("change_value",this.fatherContent.key)
        console.log("initStatus",this.initStatus)
        this.$emit('fatherCall', {
          key: this.fatherContent.key,
          value: val.value
        })

        if(this.initStatus === false){
          console.log("emmit_switchChange", val.value)
					this.$emit('switchChange', val.value)
				}
				this.initStatus = false
      },
      deep:true
    }
  },
  methods: {
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    }
  },
  created() { },
  mounted() {

    this.initStatus = this.editStatus === 'init'
    console.log("init_status,", this.fatherContent.key)
    // console.log("initStatus_value,", this.initStatus)
    this.content =
      Object.keys(this.jsonContent) > 0 ? this.jsonContent : this.fatherContent

    console.log("this.datamodel", this.datamodel)
    if (this.datamodel !== undefined) {
      if (typeof this.datamodel === 'object') {
        if(this.datamodel["value"] !== undefined) {
          this.formLabelAlign.value = this.datamodel.value
        } else if(this.datamodel[this.fatherContent.key] !== undefined) {
          this.formLabelAlign.value = this.datamodel[this.fatherContent.key]
        } else {
          this.formLabelAlign.value = this.datamodel
        }
      } else {
        this.formLabelAlign.value = this.datamodel
      }
    } else {

      if(this.fatherContent.displayReference.defaultValue === ""){
          this.formLabelAlign.value = undefined
          this.initStatus = true

          console.log("init_status——5", this.fatherContent.key)
      }else{
          /// vetrust 单独逻辑
          this.formLabelAlign.value = this.fatherContent.displayReference.defaultValue
          this.initStatus = true
          console.log("init_status——6", this.fatherContent.key)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item-content {
  padding-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}

.boolean_switch_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-height: 30px;
}

.el-radio {
  width: 50%;
  margin: 0 !important;
}
</style>
